<template>
  <section>
    <div class="background-section-title">
      <div class="section-title">
        <h2>Créditos | Planos</h2>
      </div>
    </div>
    <div class="section-content">
      <div class="list-container">
        <div class="box-body">
          <loading
            :active.sync="isLoading"
            :can-cancel="false"
            :is-full-page="fullPage"
          ></loading>
          <div class="col-sm-12">
              <div v-show="plansScreen" class="col-xs-12">
                <b-form-group>
                  <div>
                    <div id="plans" class="planContainer">
                      <PlansBox
                        :buttonClicked="true"
                        v-if="plans"
                        :positionPlan="intermediaryPlan"
                        cssPlan="plan_2"
                        planId="planId"
                        @showWizard="showWizard($event)"
                        :plans="plans"
                      ></PlansBox>
                      <PlansBox
                        :buttonClicked="true"
                        v-if="plans"
                        :positionPlan="initialPlan"
                        cssPlan="plan_1"
                        planId="planId"
                        @showWizard="showWizard($event)"
                        :plans="plans"
                      ></PlansBox>
                       <div class="advanced_plan_contact">
                        <b-card class="shadow-sm p-3 mb-5 bg-white rounded">
                            <b-card-text class="title-footer" style="color: #153252; font-weight: 500;">PLANO AVANÇADO <h3>acima de 15h / mês</h3>
                              <b-button class="button-home" href="/#/store/advanced-contact">Entre em contato</b-button>
                            </b-card-text>

                        </b-card>
                      </div>
                    </div>
                  </div>
                </b-form-group>
              </div>
            <div class="wizard">
              <b-card v-show="!plansScreen" class="shadow-sm p-3 mb-5 bg-white rounded">
              <b-tabs
                v-show="renderWizard()"
                v-model="tabIndex"
                content-class="mt-3"
                align="center"
              >
                <b-tab style="border: none;">
                  <template #title>
                    <b-card style="border: none;">
                      <b-card-title style="text-align: center; border: none;"><img :src="oneCircleIcon"></b-card-title>
                      <b-card-text class="tab-title"> Dados da compra</b-card-text>
                    </b-card>
                  </template>
                  <b-container>
                    <div class="plano-selecionado col-sm-12">
                      <div class="planContainer selectPlan">
                        <PlansBox
                          :buttonClicked="false"
                          v-if="planId"
                          :positionPlan="getSelectedPlan(planId)"
                          :recommendPng="false"
                          cssPlan="plan_1"
                          planId="planId"
                          @showWizard="showWizard($event)"
                          :plans="plans"
                        ></PlansBox>
                      </div>
                    </div>
                    <div class="col-sm-12 bottom-bar">
                      <ul class="list-inline float-right">
                        <li class="list-inline-item">
                          <b-button
                            class="button-back-style"
                            @click="backToPlanScreen()"
                            >Voltar</b-button
                          >
                        </li>
                        <li class="list-inline-item">
                          <b-button style="border:#207BDD;"
                            class="button-style"
                            variant="primary"
                            @click="goToUserFormTab()"
                            >Avançar</b-button
                          >
                        </li>
                      </ul>
                    </div>
                  </b-container>
                </b-tab>
                <b-tab :disabled="isUserFormTabDisabled">
                  <template #title>
                    <b-card style="border: none;">
                      <b-card-title style="text-align: center; border: none;"><img :src="isUserFormTabDisabled ? twoCircleIconGray : twoCircleIcon"></b-card-title>
                      <b-card-text class="tab-title"> Dados do comprador</b-card-text>
                    </b-card>
                  </template>
                  <UserForm
                    @backToInitialTab="backToInitialTab($event)"
                    @handleUserFormData="handleUserFormData($event)"
                  />
                </b-tab>
                <b-tab
                  :disabled="isPaymentFormTabDisabled"
                >
                  <template #title>
                    <b-card style="border: none;">
                      <b-card-title style="text-align: center; border: none;"><img :src="isPaymentFormTabDisabled ? treeCircleIconGray : treeCircleIcon"></b-card-title>
                      <b-card-text class="tab-title"> Dados do pagamento</b-card-text>
                    </b-card>
                  </template>
                  <PaymentForm
                    @backToUserFormTab="backToUserFormTab($event)"
                    @handlePaymentFormData="handlePaymentFormData($event)"
                  />
                </b-tab>
                <b-tab :disabled="isSummaryTabDisabled">
                  <template #title>
                    <b-card style="border: none;">
                      <b-card-title style="text-align: center; border: none;"><img :src="isSummaryTabDisabled ? fourCircleIconGray : fourCircleIcon"></b-card-title>
                      <b-card-text class="tab-title"> Resumo</b-card-text>
                    </b-card>
                  </template>
                  <b-container>
                    <div class="accordion" role="tablist">
                      <b-card no-body class="mb-1 shadow-sm p-3 mb-5 bg-white rounded">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle.accordion-1 variant="default"
                            >Dados da Compra</b-button
                          >
                        </b-card-header>
                        <b-collapse
                          id="accordion-1"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-row class="selected-value">
                              <PlansBox
                                :buttonClicked="false"
                                v-if="planId"
                                :positionPlan="getSelectedPlan(planId)"
                                :recommendPng="false"
                                cssPlan="plan_1"
                                planId="planId"
                                @showWizard="showWizard($event)"
                                :plans="plans"
                              ></PlansBox>
                            </b-row>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                      <b-card no-body class="mb-1 shadow-sm p-3 mb-5 bg-white rounded">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle.accordion-2 variant="default"
                            >Dados do Comprador</b-button
                          >
                        </b-card-header>
                        <b-collapse
                          id="accordion-2"
                          visible
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body>
                            <b-row>
                              <b-col class="col-sm-6">
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label"
                                    ><label>*Tipo: </label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input plaintext v-model="user.type">
                                    </b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label">
                                    <label v-if="user.type === 'Pessoa Física'"
                                      >CPF:</label
                                    >
                                    <label v-else>CNPJ: </label>
                                  </b-col>
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      plaintext
                                      v-model="user.personalId"
                                    >
                                    </b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label">
                                    <label>Nome: </label>
                                  </b-col>
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      plaintext
                                      v-model="user.name"
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label"
                                    ><label>Telefone: </label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      plaintext
                                      v-model="user.phone"
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label"
                                    ><label>E-mail: </label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      plaintext
                                      v-model="user.email"
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label"
                                    ><label>CEP:</label>
                                  </b-col>
                                  <b-col class="col-sm-8">
                                    <b-form-input plaintext v-model="user.zipCode">
                                    </b-form-input>
                                  </b-col>
                                </b-form-row>
                              </b-col>
                              <b-col class="col-sm-6">
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label"
                                    ><label>Logradouro:</label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      plaintext
                                      v-model="user.address"
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label"
                                    ><label>Número: </label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      plaintext
                                      v-model="user.number"
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label"
                                    ><label>Complemento:</label></b-col
                                  >
                                  <b-col class="col-sm-8"
                                    ><b-form-input
                                      plaintext
                                      v-model="user.complement"
                                    >
                                    </b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label"
                                    ><label>Bairro:</label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      plaintext
                                      v-model="user.district"
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label"
                                    ><label>Estado: </label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      plaintext
                                      v-model="user.state"
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col class="col-sm-4 control-label">
                                    <label>Cidade: </label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input plaintext v-model="user.city">
                                    </b-form-input>
                                  </b-col>
                                </b-form-row>
                              </b-col>
                            </b-row>
                          </b-card-body>
                        </b-collapse>
                      </b-card>
                      <b-card no-body class="mb-1 shadow-sm p-3 mb-5 bg-white rounded">
                        <b-card-header header-tag="header" class="p-1" role="tab">
                          <b-button block v-b-toggle.accordion-3 variant="default"
                            >Dados do Pagamento</b-button
                          >
                        </b-card-header>
                        <b-collapse
                          id="accordion-3"
                          accordion="my-accordion"
                          role="tabpanel"
                        >
                          <b-card-body class="">
                            <b-row>
                              <b-col class="col-sm-12">
                                <b-form-row>
                                  <b-col
                                    class="col-sm-4 control-label payment-label"
                                    ><label>Número do Cartão:</label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      plaintext
                                      v-model="card.number"
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col
                                    class="col-sm-4 control-label payment-label"
                                  >
                                    <label>Bandeira: </label>
                                  </b-col>
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      plaintext
                                      v-model="card.brand"
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col
                                    class="col-sm-4 control-label payment-label"
                                  >
                                    <label>Validade: </label>
                                  </b-col>
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      v-model="completeExpirationDate"
                                      plaintext
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col
                                    class="col-sm-4 control-label payment-label"
                                    ><label>Código de Segurança: </label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      v-model="card.securityCode"
                                      plaintext
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                                <b-form-row class="form-group">
                                  <b-col
                                    class="col-sm-4 control-label payment-label"
                                    ><label>Nome do Titular: </label></b-col
                                  >
                                  <b-col class="col-sm-8">
                                    <b-form-input
                                      v-model="card.holderName"
                                      plaintext
                                    ></b-form-input>
                                  </b-col>
                                </b-form-row>
                              </b-col>
                            </b-row>
                          </b-card-body>
                        </b-collapse>
                        <div class="col-sm-12 bottom-bar">
                          <ul class="list-inline float-right">
                            <li class="list-inline-item" id="voltar-compra">
                              <b-button
                                class="button-back-style"
                                @click="backToPreviousTab()"
                                >Voltar</b-button
                              >
                            </li>
                            <li class="list-inline-item">
                              <b-button class="button-style"
                                variant="primary"
                                @click="submitPaymentData()"
                                >Comprar</b-button
                              >
                            </li>
                          </ul>
                        </div>
                      </b-card>
                    </div>
                  </b-container>
                </b-tab>
              </b-tabs>
              </b-card>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import Loading from 'vue-loading-overlay';
  import PaymentForm from '../Forms/PaymentForm.vue';
  import UserForm from '../Forms/UserForm.vue';
  import PlansBox from './PlansBox.vue';
  import 'vue-loading-overlay/dist/vue-loading.css';
  import { storeService } from '../../../services/store.service';
  import { utilsService } from '../../../services/utils.service';
  import { mapActions } from 'vuex';

  import oneCircleIcon from '../../../assets/icons/one_circle.svg';
  import twoCircleIcon from '../../../assets/icons/two_circle.svg';
  import treeCircleIcon from '../../../assets/icons/tree_circle.svg';
  import fourCircleIcon from '../../../assets/icons/four_circle.svg';
  import twoCircleIconGray from '../../../assets/icons/two_circle_gray.svg';
  import treeCircleIconGray from '../../../assets/icons/tree_circle_gray.svg';
  import fourCircleIconGray from '../../../assets/icons/four_circle_gray.svg';

  export default {
    components: {
      Loading,
      PaymentForm,
      UserForm,
      PlansBox
    },
    data () {
      return {
        oneCircleIcon,
        twoCircleIcon,
        treeCircleIcon,
        fourCircleIcon,
        twoCircleIconGray,
        treeCircleIconGray,
        fourCircleIconGray,
        plans: null,
        initialPlan: 0,
        intermediaryPlan: 1,
        pickedPlan: 0,
        summaryPickedPlan: false,
        planId: '',
        amountPlan: '',
        plansScreen: true,
        isUserFormTabDisabled: true,
        isPaymentFormTabDisabled: true,
        isSummaryTabDisabled: true,
        tabIndex: 1,
        secondsQuantity: '',
        isLoading: false,
        fullPage: true,
        user: {
          name: '',
          type: 'Pessoa Física',
          personalId: '',
          phone: '',
          zipCode: '',
          address: '',
          complement: '',
          number: '',
          district: '',
          email: '',
          state: '',
          city: ''
        },
        card: {
          number: '',
          brand: '',
          expirationMonth: '',
          expirationYear: '',
          securityCode: '',
          holderName: ''
        }
      };
    },
    methods: {
      ...mapActions('account', ['balance']),
      showWizard (event) {
        this.plansScreen = event.plansScreen;
        this.planId = event.planId;
        this.amountPlan = event.amountPlan;
        this.secondsQuantity = event.secondsQuantity;
        this.summaryPickedPlan = true;
      },
      renderWizard () {
        if (this.planId) return true;
      },
      getSelectedPlan (planId) {
        return planId === 2 ? this.intermediaryPlan : this.initialPlan;
      },
      backToPlanScreen () {
        this.plansScreen = true;
        this.planId = false;
      },
      async goToUserFormTab () {
        await (this.isUserFormTabDisabled = false);
        this.goToNextTab();
      },
      backToInitialTab (event) {
        if (event.initialTab === true) {
          this.backToPreviousTab();
        }
      },
      async handleUserFormData (event) {
        if (event.nextTab === true) {
          utilsService.setUserFormData(this.user, event);
          await (this.isPaymentFormTabDisabled = false);
          this.goToNextTab();
        }
      },
      backToUserFormTab (event) {
        if (event.userFormTab === true) {
          this.backToPreviousTab();
        }
      },
      backToPreviousTab () {
        this.tabIndex--;
      },
      goToNextTab () {
        this.tabIndex++;
      },
      async handlePaymentFormData (event) {
        if (event.nextTab === true) {
          utilsService.setCardFormData(this.card, event);
          await (this.isSummaryTabDisabled = false);
          this.goToNextTab();
        }
      },
      async submitPaymentData () {
        const createPaymentProps = { api: false, user: this.user, card: this.card, loggedEmail: this.$store.state.account.user.username, paymentValue: this.amountPlan, secondsQuantity: this.secondsQuantity, extraProp: { idActivePlan: this.planId } };
        const userData = utilsService.createPaymentData(createPaymentProps);
        this.isLoading = true;
        const res = await storeService.requestTransaction(userData);
        // TODO: test after
        res ? this.redirectAfterSuccessOperation() : this.$toast.open({ message: 'Falha na transação!', type: 'error' });
        this.isLoading = false;
      },
      redirectAfterSuccessOperation () {
        this.balance();
        this.$router.push({ name: 'sucessfulPurchase' });
      }
    },
    computed: {
      completeExpirationDate () {
        return `${this.card.expirationMonth}/${this.card.expirationYear}`;
      }
    },
    async created () {
      this.plans = await storeService.plans();
    }
  };
</script>

<style lang='scss' scoped>

h3 {
  font-family: 'Poppins';
  font-size: 32px;
  display: inline-block;
  font-weight: 500;
  color: #207BDD;
  padding-left: 70px;
}

.title-footer {
  font-size: 18px;
  padding: 0 15px;
  letter-spacing: 1px;
}

.section-title {
  height: 90px;
}

.col-sm-12 {
  margin-bottom: 20px;
}

.advanced_plan_contact {
  margin-top: 20px;
  width: 100%;
}

.button-home {
  background-color: #207BDD;
  color: #fff;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  height: 36px;
  width: 184px;
  left: 1011px;
  top: 623.5px;
  border-radius: 4px;
  align-items: flex-start;
  display: inline-block;
  margin-left: 100px;
  border: none;
  padding-top: 8px;
}

.tab-title {
  margin-top: 0px;
  margin-bottom: 20px;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 41px;
  letter-spacing: 0em;
  text-align: left;
  color: #6278A3;
}

.selected-value {
  display: flex;
  align-items: center;
  justify-content: center;
}

.bottom-bar {
  margin-top: 20px;
}

.form-group {
  margin-bottom: 3px !important;
}

.control-label {
  font-size: 12px !important;
}

.payment-label {
  padding-left: 100px;
}

.planContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 0;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.selectPlan {
  color: #fff !important;
}
.plan_1,
.plan_2 {
  background: unset;
  box-sizing: border-box;
  text-align: center;
  margin: 0px;
  margin: 0px;
  padding: 0px;
  width: 49%;
}
.plan .titleContainer {
  padding: 1em;
  height: 50px;
}

.plan .titleContainer .title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bolder;
}
.plan .infoContainer {
  padding: 1em;
  color: #2d3b48;
  box-sizing: border-box;
  width: 240px !important;
}
.plan .infoContainer .price {
  font-size: 1.35em;
  font-weight: 700;
  margin-top: 0;
  display: inline-block;
  width: 80%;
}

.plan .infoContainer .price p {
  font-size: 14px;
  display: inline-block;
  margin: 0;
}
.plan_2 .price {
  color: #fff;
}
.plan_1 .features,
.plan_2 .features,
.plano_3 .features {
  color: #fff;
}
.plan .infoContainer .price span {
  font-size: 1.0125em;
  display: inline-block;
}
.plan .infoContainer .desc {
  padding-bottom: 1em;
  border-bottom: 2px solid #f3f3f3;
  margin: 0 auto;
  width: 90%;
}
.plan .infoContainer .desc em {
  font-size: 1em;
  font-weight: 500;
}
.plan .infoContainer .features {
  font-size: 1em;
  list-style: none;
  padding-left: 0;
}
.plan .infoContainer .features li {
  color: #0072c6;
}
.plan .infoContainer .selectPlan {
  border: 2px solid #0072c6;
  padding: 0.75em 1em;
  border-radius: 0.75em;
  cursor: pointer;
  transition: all 0.25s;
  margin: 2.5em auto;
  box-sizing: border-box;
  max-width: 70%;
  display: block;
  font-weight: 700;
  background: #fff;
  color: #0072c6 !important;
}

.minute {
  font-size: 12px;
  font-weight: 700;
}
.minuteValue {
  color: #ff8c00 !important;
  font-size: 32px;
  font-weight: bolder;
}

.wizard {
  margin: 0px auto;
  background: #fff;
}

.button-back-style {
  /* Rectangle 3926 */
  height: 36px;
  width: 154px;
  left: 1049px;
  top: 618px;
  color: #207bdd;
  border-radius: 4px;
  background: #FFFFFF;
  border: 1px solid #207BDD;
  box-sizing: border-box;
  border-radius: 4px;
}

.button-style {
  height: 36px;
  width: 154px;
  left: 1049px;
  top: 618px;
  border-radius: 4px;
  background: #207BDD;
  font-weight: 400 !important;
}
</style>

<template>
  <div v-bind:class="[plan, cssPlan]">
    <b-card>
      <b-card-header style="background: none; border: none;">
        <div class="title">{{ plans[positionPlan].name }}</div>
      </b-card-header>
      <b-card-body>
        <div class="minuteValue">
          <strong> R$ {{ adjustPricePlan(positionPlan) }} / min</strong>
        </div>
        <div class="price">
          <p> R$ {{ amountPlan }} por mês</p>
        </div>
        <div class="for-months">
            <p>{{ secondsQuantity }}h / mês</p>
        </div>
        <p class="validade-minutagem">Validade da minutagem: mensal</p>
        <b-button
                v-show="buttonClicked"
                @click="showWizard(plans[positionPlan].planId)"
                size="lg"
                variant="outline-primary"
                class="plan-button"
        >Assinar</b-button
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  export default {
    data () {
      return {
        plan: 'plan'
      };
    },
    props: {
      positionPlan: Number,
      recommendPng: Boolean,
      cssPlan: String,
      planId: String,
      plans: Array,
      buttonClicked: Boolean
    },
    methods: {
      adjustPricePlan (planNumber) {
        const price = this.plans[planNumber].price.toString().replace('.', ',');
        return this.addZeroToPrice(price);
      },
      addZeroToPrice (price) {
        return price.length === 3 ? price.concat('0') : price;
      },
      showWizard (planId) {
        const showWizardData = {
          plansScreen: false,
          planId: planId,
          amountPlan: this.amountPlan,
          secondsQuantity: this.secondsQuantity * 3600
        };
        console.log(showWizardData);
        this.$emit('showWizard', showWizardData);
      }
    },
    computed: {
      amountPlan () {
        return this.plans[this.positionPlan].value * this.plans[this.positionPlan].price;
      },
      secondsQuantity () {
        console.log(this.plans[this.positionPlan].monthlyHours);
        return this.plans[this.positionPlan].monthlyHours;
      }
    }
  };
</script>

<style lang='scss' scoped>

.title {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  /* identical to box height */
  text-align: center;
  font-feature-settings: 'pnum' on, 'lnum' on;

  color: #004a78;
}
.hoursMonth {
  color: #0072c6 !important;
  font-weight: 700;
  font-size: 25px;
  padding-top: 20px;
}

.price {
  font-weight: 600;
  font-size: 16px;
  line-height: 50px;
}

.planContainer {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0px;
  margin-right: 30px;
  flex-direction: row;
  align-items: flex-start;
}
.plan {
  background: #fcfdff;
  width: 20em;
  box-sizing: border-box;
  text-align: center;
  margin: 0.5em;
  margin-bottom: 1em;
  color: #2078DD;
}

.validade-minutagem {
  color: #6278A3;
  font-size: 16px;
  width: 40%;
  text-align: center;
  margin-left: 28%;
}

.selectPlan {
  color: #fff !important;
}
.plan_1,
.plan_2 {
  background: unset;
  width: 21em;
  box-sizing: border-box;
  text-align: center;
  margin: 0.5em;
  margin-bottom: 1em;
  padding: 4em;
}

.plan .titleContainer {
  padding: 1em;
  height: 50px;
}

.plan-button {
  background-color: #207bdd;
  color: #fff;
  font-family: 'Poppins';
  font-weight: 500;
  font-size: 14px;
  height: 36px;
  width: 120px;
  top: 510.5px;
  border-radius: 4px;
  padding: 4px;
  text-align: center;
}

.plan .titleContainer .title {
  font-size: 18px;
  font-family: 'Poppins';
  text-transform: uppercase;
  font-weight: bolder;
}

.plan .price {
  color: #6278A3;
  font-family: 'Poppins';
}

.plan .infoContainer .price {
  font-size: 16px;
  font-weight: 700;
  margin-top: 0;
  width: 80%;
}

.plan .infoContainer .price p {
  font-size: 14px;
  margin: 0;
}

.plan_1 .features,
.plan_2 .features {
  color: #fff;
}

.plan .infoContainer .price span {
  font-size: 1.0125em;
  font-size: 1.0125em;
}

.plan .infoContainer .desc {
  padding-bottom: 1em;
  border-bottom: 2px solid #f3f3f3;
  margin: 0 auto;
  width: 90%;
}

.plan .infoContainer .desc em {
  font-size: 1em;
  font-weight: 500;
}

.plan .infoContainer .selectPlan {
  border: 2px solid #0072c6;
  padding: 0.75em 1em;
  border-radius: 0.75em;
  cursor: pointer;
  transition: all 0.25s;
  margin: 2.5em auto;
  box-sizing: border-box;
  max-width: 70%;
  display: block;
  font-weight: 700;
  background: #fff;
  color: #0072c6 !important;
}

.for-months {
  font-weight: 600;
  font-size: 24px;
  line-height: 50px;
  font-family: 'Poppins';
}

.minute {
  font-size: 12px;
  font-weight: 700;
}
.minuteValue {
  color: #ff8c00 !important;
  font-size: 32px;
  font-weight: bolder;
  font-family: 'Poppins';
}
.plan .infoContainer .selectPlan:hover {
  background-color: #0084e5;
  color: #fff !important;
}
.plan .infoContainer .cancelPlan:hover {
  background-color: red;
  color: #fff;
}

@media screen and (max-width: 25em) {
  .planContainer {
    margin: 0;
  }
  .planContainer .plan {
    width: 100%;
    margin: 1em 0;
  }
}
</style>
